


















































import { Component, Vue } from 'vue-property-decorator';

import StartButton from '@/components/StartButton.vue';
import {
  dispatchCancelRknChecker,
  dispatchGetRknCheckerStatus,
  dispatchRunRknChecker,
} from '@/store/rkn-checker/actions';
import { readRknCheckerFinished, readRknCheckerState } from '@/store/rkn-checker/getters';
import { commitSetRknCheckerFinished } from '@/store/rkn-checker/mutations';
import { commitAddNotification } from '@/store/main/mutations';
import { delay } from '@/utils';
import { IRknCheckerTaskCreate } from '@/interfaces/rkn-checker';

const MATRIX_STATUS_ACTIVE = 1;
const MATRIX_STATUS_INERVAL = 3000; // 3 seconds

@Component({
  components: {
    StartButton,
  },
})
export default class RknChecker extends Vue {
  public timeOutPointer?: number = undefined;
  public file: File|null = null;
  public valid = false;

  get gsState() {
    return readRknCheckerState(this.$store);
  }

  get isFinished() {
    return readRknCheckerFinished(this.$store);
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      // обнуляем предыдущие значения,чтобы корректно работата кнопка "Отменить"
      this.gsState.done = null;
      this.gsState.total = null;

      this.gsState.status = 1;  // чтобы сразу сделать кнопку запуска неактивной
      const createdTask: IRknCheckerTaskCreate = { file: this.file };
      await dispatchRunRknChecker(this.$store, createdTask);
      this.checkRknCheckerStatus();
    }
  }

  public setFinished(value = true) {
    commitSetRknCheckerFinished(this.$store, value);
  }

  public async checkRknCheckerStatus(cancelled = false) {
    const data = await dispatchGetRknCheckerStatus(this.$store);
    // data.total = null;
    // data.done = null;
    if (data.status === MATRIX_STATUS_ACTIVE) {
      this.setFinished();
      clearInterval(this.timeOutPointer);
      this.timeOutPointer =  setTimeout(() => this.checkRknCheckerStatus(), MATRIX_STATUS_INERVAL);
    } else {
      this.gsState.status = null;
      if (this.isFinished) {
        const statusMsg = this.gsState.message ? '. ' + this.gsState.message : '';
        const statMsg = data.total ? `выполнено ${data.done} из ${data.total}` : '';
        const msg = statMsg + statusMsg;
        const content = cancelled
          ? 'РКН checker: задача отменена' + (msg ? '; ' + msg : '')
          : 'РКН checker: ' + msg;

        // const content = cancelled
        //   ? 'РКН checker: задача отменена'
        //   : 'РКН checker: выполнено';
        commitAddNotification(this.$store, { content, color: 'info' });
        this.setFinished(false);
      }
    }
  }

  public async mounted() {
    this.checkRknCheckerStatus();
  }

  public destroyed() {
    clearInterval(this.timeOutPointer);
  }

  public async handleCancelTask() {
    this.gsState.status = null;
    // используем delay, чтобы бэк успел обновить статус в redis
    const res = await Promise.all([dispatchCancelRknChecker(this.$store), delay(500)]);
    if (res) {
      clearInterval(this.timeOutPointer);
      this.checkRknCheckerStatus(true);
    }
  }
}
