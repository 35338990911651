























import { Component, Vue } from 'vue-property-decorator';

export interface IStatState {
  status: number | null;
  total: number | null;
  done: number | null;
}

const initialProps: IStatState = {
  status: null,
  total: null,
  done: null,
};

@Component({
  props: {
    text: String,
    state: {
      type: Object,
      default: () => (initialProps),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
})

export default class StartButton extends Vue {}
